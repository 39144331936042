import api from 'api'
// import axios from 'axios'

import CounselingCenter from '../models/counseling-center'

/** @type {import('vuex').Module<any, import('../models/counseling-center').default} */
const counselingCenterModule = {
  getters: {
    counselingCenters: function() {
      return CounselingCenter.query()
        .orderBy('name', 'asc')
        .get()
    }
  },
  actions: {
    loadCounselingCenters: async function({ commit }) {
      commit('setPageDataLoading', true)

      try {
        const counselingCenters = await api.call('getCounselingCenters')
        CounselingCenter.deleteAll()
        CounselingCenter.create({ data: counselingCenters.data.results })
      } catch (error) {
        return error
      } finally {
        commit('setPageDataLoading', false)
      }

      // return api.call('getCounselingCenters')
    },
    changeCounselingCenter: async function(context, counselingCenterId) {
      await api.call('changeCounselingCenter', counselingCenterId)
    }
  }
}

export default counselingCenterModule
