import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill'
import ReconnectingEventSource from 'reconnecting-eventsource'
import debounce from 'lodash/debounce'
import api from 'api'
import { mercureHubUrl } from 'api/config'
import { setThemeFromObject, convertColorConfig } from '../shared/color'

// const EventSource = NativeEventSource || EventSourcePolyfill
global.EventSource = NativeEventSource || EventSourcePolyfill

export const setOffcanvas = ({ commit }, val) => {
  commit('setOffcanvas', val)
}

export const setDialogOpen = ({ commit, dispatch }, val) => {
  commit('setDialogOpen', val)

  if (val === false) {
    dispatch('clearDialog')
  }
}

export const setChatDialogOpen = ({ commit }, val) => {
  commit('setChatDialogOpen', val)
}

export const resetEditMessages = ({ commit }, msg) =>
  commit('resetEditMessages', msg)

export const setInterruptedRoute = ({ commit }, route) =>
  commit('setInterruptedRoute', route)

export const setEditMessage = ({ commit }, val) => {
  commit('setEditMessage', val)
}

export const setInitialEditMessage = ({ commit }, val) => {
  commit('setInitialEditMessage', val)
}

export const setMessageEditorOpen = ({ commit }, val) => {
  commit('setMessageEditorOpen', val)
}
export const setConfirmOpen = ({ commit }, val) => {
  commit('setConfirmOpen', val)
}

export const toggleDialogLoading = ({ commit, state }, val) => {
  if (state.dialogLoading.indexOf(val) > -1) {
    commit('untoggleDialogLoading', val)
  } else {
    commit('toggleDialogLoading', val)
  }
}

/**
 * Reset Global State
 */
export const resetState = ({ commit, dispatch }) => {
  dispatch('entities/deleteAll', {})
  commit('resetState')
}

/**
 * Message Api Actions
 */

const debouncedGetSentMessages = debounce(async function(commit) {
  commit('setDialogsLoading', true)

  const response = await api.call('getSentMessages')
  commit('storeSentMessages', response.data.results)
  commit('setDialogsLoading', false)
}, 300)

export const getSentMessages = ({ commit }) => {
  debouncedGetSentMessages(commit)
}

const debouncedGetDialogs = debounce(async function(commit, dispatch) {
  commit('setDialogsLoading', true)
  const response = await api.call('getDialogs')
  dispatch('entities/dialogs/create', { data: response.data.results })
  commit('setDialogsLoading', false)
}, 300)

export const getDialogs = ({ commit, dispatch }) => {
  debouncedGetDialogs(commit, dispatch)
}

const debouncedGetChatDialogs = debounce(async function(commit, dispatch) {
  commit('setDialogsLoading', true)
  const response = await api.call('getChatDialogs')
  dispatch('entities/chatDialogs/create', { data: response.data.results })
  commit('setDialogsLoading', false)
}, 300)

export const getChatDialogs = ({ commit, dispatch }) => {
  debouncedGetChatDialogs(commit, dispatch)
}

const debouncedGetDialog = async (commit, dispatch, id) => {
  dispatch('toggleDialogLoading', id)
  const response = await api.call('getDialog', id)
  dispatch('entities/dialogMessages/create', { data: response.data.results })
  dispatch('toggleDialogLoading', id)
  return true
}

export const getDialog = async ({ commit, dispatch }, id) => {
  await debouncedGetDialog(commit, dispatch, id)
  return true
}

const debouncedGetChatDialog = async (commit, dispatch, id) => {
  dispatch('toggleDialogLoading', id)
  const response = await api.call('getChatDialog', id)
  dispatch('entities/chatDialogMessages/create', {
    data: response.data.results.map(item => {
      return { rootId: item.chatId, authorId: item.userId, ...item }
    })
  })
  // commit('setCurrentUserChat', { id: response.data.id, messages: response.data.results, status: 'readonly' })
  dispatch('toggleDialogLoading', id)
  return true
}

export const getChatDialog = async ({ commit, dispatch }, id) => {
  await debouncedGetChatDialog(commit, dispatch, id)
  return true
}

let currentChatEventSource
let chatEventAndStatsEventSource

export const startChatEventAndStatsSse = ({ commit, dispatch, getters }) => {
  if (
    chatEventAndStatsEventSource &&
    chatEventAndStatsEventSource.readyState !== 2
  ) {
    // check if already exists
    return
  }
  const userInfo = getters.userInfo
  if (!userInfo || !userInfo.id) {
    return
  }
  const url = new URL(mercureHubUrl)
  url.searchParams.append(
    'topic',
    `http://mercure.local/${userInfo.counselingCenterId}/chatEvent`
  )
  url.searchParams.append(
    'topic',
    `http://mercure.local/${userInfo.counselingCenterId}/chatEvent/${userInfo.id}`
  )
  url.searchParams.append(
    'topic',
    `http://mercure.local/${userInfo.counselingCenterId}/counselingEvents`
  )
  url.searchParams.append(
    'topic',
    `http://mercure.local/currentChat/${userInfo.id}`
  )
  url.searchParams.append('topic', `http://mercure.local/stats/${userInfo.id}`)

  chatEventAndStatsEventSource = new ReconnectingEventSource(url.href, {
    withCredentials: true
  })

  chatEventAndStatsEventSource.addEventListener('stats', ev => {
    let response = JSON.parse(ev.data)
    dispatch('entities/user/update', {
      where: response.id,
      data: response
    })
  })

  chatEventAndStatsEventSource.addEventListener('currentChatEvent', ev => {
    let response = JSON.parse(ev.data)
    commit('setCurrentChatEvent', response)
  })

  chatEventAndStatsEventSource.addEventListener('counselingEvent', ev => {
    let data = JSON.parse(ev.data)
    dispatch('handleUpdateCounselingEvent', data)
  })

  chatEventAndStatsEventSource.addEventListener(
    'counselingEventChat',
    ev => {
      let response = JSON.parse(ev.data)

      dispatch('entities/chatMessage/create', { data: response.messages })
      dispatch('entities/chat/create', { data: response })
    }
  )
}

export const startCurrentChatSse = ({ commit, getters, state }) => {
  const userInfo = getters.userInfo
  if (
    (currentChatEventSource && currentChatEventSource.readyState !== 2) ||
    !userInfo ||
    !userInfo.id ||
    !userInfo.lastEventId
  ) {
    // check if already exists
    return
  }
  const url = new URL(mercureHubUrl)
  url.searchParams.append(
    'topic',
    `http://mercure.local/currentChat/${userInfo.currentChatId}`
  )
  url.searchParams.append('Last-Event-ID', userInfo.lastEventId)

  currentChatEventSource = new ReconnectingEventSource(url.href, {
    withCredentials: true
  })
  console.log('startCurrentChatSse')

  currentChatEventSource.addEventListener('currentUserChat', ev => {
    let currentUserChat = getters.currentUserChat
    let response = JSON.parse(ev.data)

    const msgLength = response.messages.length
    const newMessage = response.messages[msgLength - 1]
    if (newMessage !== undefined) {
      commit(
        'setNewChatMessage',
        currentUserChat &&
          currentUserChat.messages.length !== msgLength &&
          newMessage.userId !== getters.userInfo.id
      )
    }
    if (response.status === 'pending') {
      response = { ...state.currentUserChat, ...response }
    }
    commit('setCurrentUserChat', response)
  })

  currentChatEventSource.addEventListener('currentPendingUserChat', ev => {
    let response = JSON.parse(ev.data)

    commit('setCurrentUserChat', { ...state.currentUserChat, ...response })
  })

  currentChatEventSource.addEventListener('typingState', ev => {
    let response = JSON.parse(ev.data)
    if (response.userId !== getters.userInfo.id) {
      commit('setTypingState', response.value)
    }
  })
}

export const stopSse = () => {
  if (chatEventAndStatsEventSource) {
    chatEventAndStatsEventSource.close()
  }
  if (currentChatEventSource) {
    currentChatEventSource.close()
  }
}

export const stopCurrentChatSse = ({ commit }) => {
  console.log('stop chatSse')
  if (currentChatEventSource) {
    currentChatEventSource.close()
    commit('setCurrentUserChat', null)
  }
}

export const setPageHidden = ({ commit }, val) => {
  commit('setPageHidden', val)
}

export const startCurrentUserChat = ({ commit }, id) => {
  commit('setCurrentUserChat', {
    id
  })
}

export const setCounselingCenterId = ({ commit }, id) => {
  commit('setCounselingCenterId', id)
}

export const loadUpcomingChatEvents = async ({ commit }, counselingCenterId) => {
    commit('setPageDataLoading', true)

    try {
      const response = await api.call('getUpcomingChatEvents', false, {
        counselingCenterId
      })
      commit('setUpcomingChatEvents', response.data.results)
      return
    } catch (error) {
      console.error(error) // eslint-disable-line
    } finally {
      commit('setPageDataLoading', false)
    }
}

export const setCounselingCenterTheme = ({ getters }) => {
  const counselingCenter = getters.counselingCenters.find(
    cc => cc.id === getters.counselingCenterId
  )
  if (!counselingCenter) {
    return
  }
  if (!counselingCenter.colorConfig) {
    return
  }

  setThemeFromObject(convertColorConfig(counselingCenter.colorConfig))
}

export const setThemeFromConfig = (_, payload) => {
  if (!payload) {
    return
  }

  setThemeFromObject(convertColorConfig(payload))
}
