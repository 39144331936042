/* eslint-disable no-undef */
import { Model } from '@vuex-orm/core'

export default class Faq extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'faq'

  static primaryKey = 'id'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  /* eslint-disable camelcase */
  static fields() {
    return {
      id: this.attr(null),
      date: this.attr(new Date()),
      answer: this.attr(''),
      question: this.attr(''),
      roleId: this.attr(null),
      position: this.attr(null)
    }
  }
}
