<template>
  <router-view :class="`e-theme--${theme}`" />
</template>

<script>
import Favico from 'favico.js'
import { userActions } from 'mixins'
import { mapActions, mapGetters } from 'vuex'
import { interceptors } from 'api/interceptors'
import supportedBrowsers from './shared/supportedBrowsers'

const isPageHidden = () => {
  return (
    window.document.hidden ||
    window.document.msHidden ||
    window.document.webkitHidden ||
    window.document.mozHidden
  )
}

export default {
  /** ========================================== */
  /*     MIXINS                                  */
  /** ========================================== */
  mixins: [userActions],

  data: () => ({
    activeIcon: 0,
    animationInterval: null,
    notificationIcons: {
      mail: [
        require('assets/bitmaps/mail-envelope-closed.png'),
        require('assets/bitmaps/mail.png')
      ],
      chat: [
        require('assets/bitmaps/bubbles4.png'),
        require('assets/bitmaps/bubbles.png')
      ]
    },
    notificationImage: document.createElement('img'),
    favicon: new Favico({
      animation: 'pop',
      element: document.querySelector("link[rel='shortcut icon']")
    }),
    closeChatMessageWasShown: false
    // unwatchChat: null
  }),

  /** ========================================== */
  /*     COMPUTED                                */
  /** ========================================== */
  computed: {
    ...mapGetters([
      'userInfo',
      'gdprConfirmationNeeded',
      'theme',
      'needsChatInteraction',
      'needsMailInteraction',
      'chatIsOpen',
      'chatWasCanceled',
      'currentUserChat',
      'counselingCenterId',
      'counselingCenters'
    ])
  },

  /** ========================================== */
  /*     WATCH                                   */
  /** ========================================== */
  watch: {
    $route(newVal) {
      this.setOffcanvas(false)
      if (
        this.gdprConfirmationNeeded &&
        this.$route.name !== 'gdpr' &&
        this.$route.path !== '/content/privacy'
      ) {
        this.$router.push({
          name: 'gdpr'
        })
      }

      if (
        newVal.query.c &&
        !(this.userInfo && this.userInfo.counselingCenterId)
      ) {
        this.setCounselingCenterId(this.$route.query.c)
        this.getAnonymousCounselingEvents(this.$route.query.c)
      }
    },
    gdprConfirmationNeeded() {
      if (
        this.gdprConfirmationNeeded &&
        this.$route.name !== 'gdpr' &&
        this.$route.path !== '/content/privacy'
      ) {
        this.$router.push({
          name: 'gdpr'
        })
      }
    },

    needsChatInteraction: {
      handler: function(val) {
        if (this.animationInterval !== null) {
          clearInterval(this.animationInterval)
          this.animationInterval = null
        }
        if (val) {
          const that = this
          this.animationInterval = setInterval(() => {
            that.notificationImage.setAttribute(
              'src',
              that.notificationIcons.chat[that.activeIcon]
            )
            that.favicon.image(that.notificationImage)
            that.activeIcon = (that.activeIcon + 1) % 2
          }, 1500)
          return
        }
        this.favicon.reset()
      },
      immediate: true
    },

    chatIsOpen(newVal) {
      if (!this.userInfo || newVal === true || this.closeChatMessageWasShown) {
        return
      }
      if (
        this.chatWasCanceled ||
        (this.$route.name === 'liveChat' && !this.currentUserChat)
      ) {
        this.closeChatMessageWasShown = true
        this.$alert(this.$t('chat.openingTimeEnded'), {
          type: 'warning'
        }).finally(async () => {
          this.$router.push({ name: 'chatDialogs' })
          this.closeChatMessageWasShown = false
        })
      }
    },
    userInfo: {
      handler: function(newVal, oldVal) {
        if (!newVal) {
          this.stopSse()
          return
        }
        if (!oldVal) {
          setTimeout(this.startChatEventAndStatsSse, 10)
        }
        const newEventId =
          newVal && newVal.lastEventId ? newVal.lastEventId : null
        const oldEventId =
          oldVal && oldVal.lastEventId ? oldVal.lastEventId : null
        if (
          newVal &&
          newVal.counselingCenterId &&
          !(oldVal && oldVal.counselingCenterId === newVal.counselingCenterId)
        ) {
          this.setCounselingCenter(newVal.counselingCenterId)
        }
        if (newEventId === null) {
          this.stopCurrentChatSse()
          return
        }
        if (newEventId !== oldEventId) {
          this.startCurrentChatSse()
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (typeof this.userInfo === 'undefined') {
      this.__user__getUserInfoFromCookie()
    }

    if (
      window.announcement &&
      window.announcement !== [] &&
      window.announcement.length > 0
    ) {
      this.storeAnnouncement(window.announcement[0])
    }
    interceptors(this)

    this.setupFaviconNotifications()
    this.loadCounselingCenters().then(() => {
      if (!this.userInfo && this.counselingCenters.length === 1 && this.counselingCenters[0].isActive) {
        this.setCounselingCenterId(this.counselingCenters[0].id)
      }
      this.setTheme()
    })
  },

  mounted() {
    if (!supportedBrowsers.test(navigator.userAgent)) {
      this.$alert(
        'Dein Browser ist veraltet, bitte verwende einen aktuellen Browser.'
      )
    }
  },

  /** ========================================== */
  /*     METHODS                                 */
  /** ========================================== */
  methods: {
    ...mapActions([
      'setOffcanvas',
      'storeAnnouncement',
      'startChatEventAndStatsSse',
      'stopSse',
      'setPageHidden',
      'setCurrentChatEvent',
      'startCurrentChatSse',
      'stopCurrentChatSse',
      'startChatEventAndStatsSse',
      'setCounselingCenterId',
      'loadCounselingCenters',
      'setCounselingCenterTheme',
      'setThemeFromConfig',
      'getCounselingEvents',
      'getAnonymousCounselingEvents',
      'loadUpcomingChatEvents'
    ]),

    setupFaviconNotifications() {
      // first we have to remove other favicon links
      document
        .querySelectorAll("link[rel='icon']")
        .forEach(el => el.parentElement.removeChild(el))
      document.addEventListener('visibilitychange', () =>
        this.setPageHidden(!!isPageHidden())
      )
    },
    setCounselingCenter(counselingCenterId) {
      this.setCounselingCenterId(counselingCenterId)
      this.setTheme()
      this.getCounselingEvents()
      this.loadUpcomingChatEvents(counselingCenterId)
    },
    setTheme() {
      if (window.colorConfig) {
        this.setThemeFromConfig(window.colorConfig)
        return
      }
      this.setCounselingCenterTheme()
    }
  }
}
</script>

<style lang="scss">
// @import '../style/style';
</style>
