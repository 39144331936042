import Chat from '../models/chat'
export default {
  getters: {
    chats: () =>
      Chat.query()
        .with('messages')
        .orderBy('timestamp', 'asc')
        .get(),
  }
}
