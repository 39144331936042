export const emptyMessage = {
  id: null,
  rootId: null,
  parentId: null,
  draft: 1,
  tempSave: true,
  subject: '',
  body: ''
}

export const state = () => ({
  // UI
  offcanvasOpen: false,
  confirmOpen: false,
  dialogOpen: false,
  chatDialogOpen: false,
  messageEditorOpen: false,
  dialogsLoading: false,
  dialogLoading: [],
  pageDataLoading: false,
  currentChatEvent: window.currentChatEvent,
  currentUserChat: null,
  newChatMessage: false,
  typingState: false,
  idleTimeSeconds: window.idleTimeSeconds,

  isPageHidden: false,

  // User
  userInfo: {},

  // Dialog
  sentMessages: [],
  // dialogs: [],
  // dialog: {},
  initialEditMessage: emptyMessage,
  editMessage: emptyMessage,

  // Router
  interruptedRoute: null,
  counselingCenterId: null,
  counselingEventTotalCount: null,
  upcomingChatEvents: []
})

// export default state
