import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import { state } from './state'
import { ORMDatabase, ORMModules } from './orm'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

Vue.use(Vuex)

const store = new Store({
  plugins: [ORMDatabase],
  modules: ORMModules,
  actions,
  getters,
  mutations,
  state: state()
})

export default store
