/* eslint-disable no-undef */
import { Model } from '@vuex-orm/core'

export default class CounselingEvent extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'counselingEvent'

  static primaryKey = 'id'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  /* eslint-disable camelcase */
  static fields() {
    return {
      id: this.attr(null),
      beginning: this.attr(new Date()),
      end: this.attr(new Date()),
      subject: this.attr(''),
      description: this.attr(''),
      channel: this.attr(''),
      channels: this.attr([]),
      remark: this.attr(''),
      bookedDescription: this.attr(''),
      status: this.attr(''),
      chatId: this.attr('')
    }
  }
}
