import api from 'api'

export default {
  /**
   * Getters
   */
  getters: {
    faq: (state, getters) =>
      getters['entities/faq/query']()
        .orderBy('position', 'asc')
        .get()
  },

  /**
   * Actions
   */
  actions: {
    getFAQ: async ({ commit, dispatch }) => {
      commit('setPageDataLoading', true)

      try {
        const response = await api.call('getFAQ')
        dispatch('entities/faq/create', { data: response.data.results })
        return
      } catch (error) {
        console.error(error) // eslint-disable-line
      } finally {
        commit('setPageDataLoading', false)
      }
    }
  }
}
