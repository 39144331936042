/* eslint-disable no-undef */
import { Model } from '@vuex-orm/core'

export default class CounselingCenter extends Model {
  static entity = 'counseling-center'
  static primaryKey = 'id'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      image: this.attr(null),
      description: this.attr(null),
      isActive: this.attr(null),
      registrationClosed: this.attr(false),
      registrationClosedLoginText: this.attr(''),
      registrationClosedMessageText: this.attr(''),
      counselingEventsEnabled: this.attr(false),
      colorConfig: this.attr(null)
    }
  }
}
