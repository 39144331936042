/* eslint-disable no-undef */
import { Model } from '@vuex-orm/core'
import ChatMessage from './chatMessage'

export default class Chat extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'chat'

  static primaryKey = 'id'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  /* eslint-disable camelcase */
  static fields() {
    return {
      id: this.attr(''),
      eventId: this.attr(''),
      status: this.attr(''),
      kid: this.attr(''),
      yearOfBirth: this.attr(null),
      gender: this.attr(null),
      counselor: this.attr(''),
      pausentaste: this.attr(false),
      messages: this.hasMany(ChatMessage, 'chatId'),
      closedInfoRead: this.attr(false),
      closedBy: this.attr(null),
      updatedAt: this.attr(''),
      acceptedAt: this.attr(null)
    }
  }
}
