/* eslint-disable no-undef */
import { Model } from '@vuex-orm/core'

export default class Chat extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'chatMessage'

  static primaryKey = 'id'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  /* eslint-disable camelcase */
  static fields() {
    return {
      id: this.attr(''),
      chatId: this.attr(''),
      userId: this.attr(''),
      sentByKid: this.attr(false),
      userName: this.attr(''),
      body: this.attr(''),
      isDeleted: this.attr(false),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
      timestamp: this.attr(''),
      kid: this.attr(''),
      counselor: this.attr('')
    }
  }
}
