import api, { HTTP } from 'api'

const parseBearerToken = req => {
  let auth
  if (!req.headers || !(auth = req.headers.authorization)) {
    return null
  }
  const parts = auth.split(' ')
  if (parts.length < 2) return null
  const schema = parts.shift().toLowerCase()
  const token = parts.join(' ')
  if (schema !== 'bearer') return null
  return token
}

export const interceptors = vm => {
  if (HTTP.interceptors.response.handlers.length < 1) {
    HTTP.interceptors.response.use(
      response => {
        if (response.data && response.data.redirect) {
          return response
        }
        // Set new token
        const authToken = parseBearerToken(response)
        if (authToken !== null) {
          api.call('setAuthToken', false, authToken)
        }

        return response
      },
      error => {
        const standardError = {
          status: 400,
          messages: []
        }

        if (error.response) {
          if (error.response.status) {
            if (error.response.status === 401) {
              vm.__user__logout()
            }
            standardError.status = error.response.status
          }

          if (error.response.data) {
            if (error.response.data.errors) {
              standardError.messages = error.response.data.errors
            } else {
              standardError.messages = error.response.data
            }
          }
        } else {
          standardError.status = 500
          standardError.messages = ['messages.errors.unknown']
        }

        return Promise.reject(standardError)
      }
    )
  }
}
