export default {
  getters: {
    userInfo: (state, getters) => getters['entities/user/query']().get()[0],
    gdprConfirmationNeeded: (state, getters) => {
      const userInfo = getters.userInfo
      return (
        userInfo &&
        (userInfo.gdprUpdate === null ||
          userInfo.gdprUpdate < window.gdprVersion)
      )
    }
  },
  actions: {
    setUserInfo({ dispatch }, val) {
      dispatch('entities/user/insert', { data: val })
    },
    updateUserInfo({ dispatch }, val) {
      dispatch('entities/user/update', {
        where: val.id,
        data: val.data
      })
    }
  }
}
