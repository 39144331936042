import api from 'api'
import Chat from '../models/chat'
import ChatMessage from '../models/chatMessage'

export default {
  /**
   * Getters
   */
  getters: {
    counselingEvents: (state, getters) =>
      getters['entities/counselingEvent/query']()
        .orderBy('beginning', 'asc')
        .get(),
    activeChatCounselingEventById: (state, getters) => id =>
      getters['entities/counselingEvent/query']()
        .where(el => {
          return el.status === 'booked' && el.channel === 'chat' && el.id === id
        })
        .first()
  },

  /**
   * Actions
   */
  actions: {
    editCounselingEvent: ({dispatch}, partialEvent) => {
      dispatch('entities/counselingEvent/update', {
        where: partialEvent.id,
        data: partialEvent

      })
    },
    getCounselingEvents: async ({ getters, commit, dispatch }) => {
      if (!getters.counselingEventsEnabled) {
        return
      }
      commit('setPageDataLoading', true)

      try {
        const response = await api.call('getCounselingEvents')
        dispatch('entities/counselingEvent/create', {
          data: response.data.results
        })
        commit('setCounselingEventTotalCount', response.data.metadata.count)
        return
      } catch (error) {
        console.error(error) // eslint-disable-line
      } finally {
        commit('setPageDataLoading', false)
      }
    },
    getAnonymousCounselingEvents: async (
      { commit, dispatch },
      counselingCenterId
    ) => {
      commit('setPageDataLoading', true)

      try {
        const response = await api.call('getAnonymousCounselingEvents', false, {
          counselingCenterId
        })
        dispatch('entities/counselingEvent/create', {
          data: response.data.results
        })
        return
      } catch (error) {
        console.error(error) // eslint-disable-line
      } finally {
        commit('setPageDataLoading', false)
      }
    },
    loadCounselingEventChat: async ({ commit }, counselingEventId) => {
      commit('setPageDataLoading', true)

      try {
        const response = await api.call(
          'getCounselingEventChat',
          counselingEventId,
          {}
        )
        ChatMessage.create({ data: response.data.messages })
        Chat.create({ data: response.data })
        return
      } catch (error) {
        console.error(error) // eslint-disable-line
      } finally {
        commit('setPageDataLoading', false)
      }
    },
    bookCounselingEvent: async ({ commit }, { id, channel, remark }) => {
      commit('setPageDataLoading', true)

      try {
        await api.call('bookCounselingEvent', id, { channel, remark })
        commit('setPageDataLoading', false)
      } catch (error) {
        commit('setPageDataLoading', false)
        throw error
      }
    },
    updateKidBooking: async ({ commit }, { id, channel, remark }) => {
      commit('setPageDataLoading', true)

      try {
        await api.call('updateKidBooking', id, { channel, remark })
        commit('setPageDataLoading', false)
      } catch (error) {
        commit('setPageDataLoading', false)
        throw error
      }
    },
    cancelCounselingEvent: async ({ commit }, { id, reason }) => {
      commit('setPageDataLoading', true)

      try {
        await api.call('cancelCounselingEvent', id, { reason })
        commit('setPageDataLoading', false)
      } catch (error) {
        commit('setPageDataLoading', false)
        throw error
      }
    },
    handleUpdateCounselingEvent: async ({ dispatch }) => {
      dispatch('getCounselingEvents')
    }
  }
}
