import axios from 'axios'
import VueCookie from 'vue-cookie'
import { restapi } from './config'
import store from '../../store'

/**
 * Axios HTTP-Request
 */
export const HTTP = axios.create({
  baseURL: restapi[process.env.NODE_ENV].url,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  withCredentials: process.env.NODE_ENV === 'development'
})

export const HTTPTEST = axios.create({
  baseURL: restapi.test.url
})

export const counselingCenterLogoUrl = id => {
  const baseUrl = restapi[process.env.NODE_ENV].url
  return `${baseUrl}/counseling-centers/download/${id}`
}

/**
 * API Class
 */
class Api {
  parseJwt(token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }
  // ==============================
  // GET
  // ==============================
  get(url, params, test = true) {
    return test ? HTTPTEST.get(url, params) : HTTP.get(url, params)
  }

  // ==============================
  // POST
  // ==============================
  post(url, params, test = true) {
    return test ? HTTPTEST.post(url, params) : HTTP.post(url, params)
  }

  // ==============================
  // POST
  // ==============================
  patch(url, params, test = true) {
    return test ? HTTPTEST.patch(url, params) : HTTP.patch(url, params)
  }

  // ==============================
  // DELETE
  // ==============================
  delete(url, params, test = true) {
    return test ? HTTPTEST.delete(url, params) : HTTP.delete(url, params)
  }

  // ==============================
  // SET AUTHENTIFICATION
  // ==============================
  setAuth(token) {
    if (token) {
      const parsedToken = this.parseJwt(token)
      // if (parsedToken.exp) store.dispatch('setTokenExpire', parsedToken.exp)

      if (parsedToken.userInfo) {
        store.dispatch('setUserInfo', parsedToken.userInfo)

        VueCookie.set('token', token)

        HTTP.defaults.headers.common['Authorization'] = token
      }
    } else {
      this.unsetAuth()
    }

    return 'Auth set'
  }

  // ==============================
  // UNSET AUTHENTIFICATION
  // ==============================
  unsetAuth() {
    delete HTTP.defaults.headers.common.Authorization
    this.post(`users/logout`, false, false)

    return 'Auth unset'
  }

  // ==============================
  // LANG
  // ==============================
  getLang(url) {
    return HTTP.get(url, {
      baseURL: restapi[process.env.NODE_ENV].lang
    })
  }

  // ==============================
  // ROUTING
  // ==============================
  call(route, id = false, params) {
    let response = null
    const userId = store.getters.userInfo ? store.getters.userInfo.id : false

    switch (route) {
      // ====================================
      // Messages
      // ====================================
      case 'getDialog':
        response = this.get(`users/${userId}/dialogs/${id}`, params, false)
        break

      case 'getDialogs':
        response = this.get(`users/${userId}/dialogs`, params, false)
        break

      case 'getChatDialog':
        response = this.get(`chat/${userId}/${id}`, params, false)
        break

      case 'getChatDialogs':
        response = this.get(`chat/${userId}/list`, params, false)
        break

      case 'getSentMessages':
        response = this.get(`users/${userId}/sent`, params, false)
        break

      case 'sendMessage':
        if (id.id) {
          response = this.patch(
            `users/${userId}/dialogs/${
              id.rootId ? id.rootId : id.id
            }/messages/${id.id}`,
            params,
            false
          )
        } else {
          response = this.post(`users/${userId}/dialogs`, params, false)
        }
        break

      case 'deleteMessage':
        response = this.delete(
          `users/${userId}/dialogs/${id.rootId ? id.rootId : id.id}/messages/${
            id.id
          }`,
          false,
          false
        )
        break

      case 'markAsRead':
        response = this.patch(
          `users/${userId}/dialogs/${id.rootId}/messages/${id.id}/read`,
          params,
          false
        )
        break

      // ====================================
      // User
      // ====================================
      case 'userLogin':
        response = this.post('users/login', params, false)
        break

      case 'afterLogin':
        response = this.get('users/after-login', false, false)
        break

      case 'changeUserData':
        response = this.patch(`users/${userId}`, params, false)
        break

      case 'changePassword':
        response = this.patch(`users/${userId}/password`, params, false)
        break

      case 'checkUsernameExists':
        response = this.get(`users/username/${id}`, null, false)
        break

      case 'checkEmailExists':
        response = this.get(`users/email/${id}`, null, false)
        break

      case 'deleteAccount':
        response = this.delete(`users/${userId}`, null, false)
        break

      case 'confirmGdpr':
        response = this.patch(`users/${userId}/confirm-gdpr`, null, false)
        break

      case 'disableHint':
        response = this.patch(`users/${userId}/hint`, null, false)
        break

      case 'forgotUsername':
        response = this.post(`users/forgot-username`, params, false)
        break

      case 'forgotPassword':
        response = this.post(`users/forgot-password`, params, false)
        break

      case 'getUserInfo':
        response = this.get(`users/${userId}`, null, false)
        break

      case 'registerUser':
        response = this.post('users/register', params, false)
        break

      case 'resendConfirm':
        response = this.post(`users/reconfirm`, params, false)
        break

      case 'resetPassword':
        response = this.post(`users/reset-password`, params, false)
        break

      case 'setAuthToken':
        response = this.setAuth(params)
        break

      case 'unsetAuthToken':
        response = this.unsetAuth()
        break

      // ====================================
      // CONTENT
      // ====================================
      case 'getFAQ':
        response = this.get(`contents/faq`, params, false)
        break

      case 'getContent':
        response = this.get(`contents/${id}`, false, false)
        break

      // ====================================
      // COUNSELING EVENT
      // ====================================
      case 'getCounselingEvents':
        response = this.get(`counseling-event`, params, false)
        break

      case 'getCounselingEventChat':
        response = this.get(`counseling-event/${id}/chat`, params, false)
        break

      case 'getAnonymousCounselingEvents':
        response = this.get(
          `events/${params.counselingCenterId}`,
          params,
          false
        )
        break

      case 'getUpcomingChatEvents':
        response = this.get(
          `chat-events/${params.counselingCenterId}`,
          params,
          false
        )
        break

      case 'bookCounselingEvent':
        response = this.post(`counseling-event/${id}/book`, params, false)
        break

      case 'updateKidBooking':
        response = this.patch(`counseling-event/${id}/book`, params, false)
        break

      case 'cancelCounselingEvent':
        response = this.post(`counseling-event/${id}/cancel`, params, false)
        break

      // ====================================
      // FEEDBACK
      // ====================================
      case 'getFeedbackData':
        response = this.get(`users/feedback`, null, false)
        break

      case 'sendFeedback':
        response = this.post(`users/${userId}/feedback`, params, false)
        break

      // ====================================
      // CHAT
      // ====================================
      case 'sendChatMessage':
        if (!id) {
          response = this.post(`chat/${userId}`, params, false)
          break
        }
        response = this.post(`chat/${userId}/${id}`, params, false)
        break
      case 'getChatMessages':
        response = this.get(`chat/${userId}/${id}`, false, false)
        break

      case 'deleteChatMessage':
        response = this.delete(
          `chat/${userId}/${id.rootId ? id.rootId : id.id}/message/${id.id}`,
          false,
          false
        )
        break
      case 'updateChat':
        response = this.post(`chat/${userId}/update/${id}`, false, false)
        break

      case 'deleteUserChat':
        response = this.delete(`chat/${userId}/${id}`, false, false)
        break

      case 'cancelChat':
        response = this.delete(`chat/${userId}/${id}/cancel`, false, false)
        break
      case 'closeChat':
        response = this.patch(`chat/${userId}/${id}`, false, false)
        break
      case 'closedChatInfoRead':
        response = this.patch(
          `chat/${userId}/${id}/closed-info-read`,
          null,
          false
        )
        break

      // ===================================
      // Counseling Centers
      // ===================================
      case 'getCounselingCenters':
        response = this.get(`counseling-centers`, params, false)
        break

      case 'changeCounselingCenter':
        response = this.post(
          `counseling-centers/change-after-deletion/${id}`,
          params,
          false
        )
        break

      // ====================================
      // DEFAULT
      // ====================================
      default:
        response = new Promise((resolve, reject) => {
          reject(new Error(`Couldn't find request-type '${route}'`))
        })
        break
    }

    return response
  }
}

const api = new Api()

export default api
