export function sanitize (html) {
  const allowedTags = [
    'a', 'div', 'span', 'p', 'b', 'u', 'i', 'br', 'strong', 'blockquote', 'em', 's', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'li', 'ol'
  ]
  const allowedAttributes = {
    'a': ['href', 'target']
  }
  return _sanitize(html, allowedTags, allowedAttributes)
}

export function sanitizeStrict (html) {
  const allowedTags = [
    'div', 'span', 'p', 'b', 'strong', 'i', 'em', 'u', 
  ]
  const allowedAttributes = {
  }
  return _sanitize(html, allowedTags, allowedAttributes)
}

function _sanitize (html, allowedTags, allowedAttributes) {
  if (!html) {
    return html
  }
  const parser = new DOMParser()
  const parsedDocument = parser.parseFromString(html, 'text/html')
  sanitizeDocumentNode(parsedDocument.body, allowedTags, allowedAttributes, true)
  return parsedDocument.body ? parsedDocument.body.innerHTML : ''
}


function sanitizeDocumentNode (node, allowedTags, allowedAttributes, root = false) {
  if (!node) {
    return
  }
  const tag = node.tagName.toLowerCase()
  if (!allowedTags.includes(tag) && !(root && tag === 'body')) {
    node.remove()
  }
  for (const attribute of node.attributes) {
    const lcAttribute = attribute.name.toLowerCase()
    if (!(tag in allowedAttributes)) {
      node.removeAttribute(lcAttribute)
      continue
    }
    const allowed = allowedAttributes[tag]
    if (!allowed.includes(lcAttribute)) {
      node.removeAttribute(lcAttribute)
    }
    else if (lcAttribute === 'href' && shouldRemoveHref(attribute.value)) {
      node.removeAttribute(lcAttribute)
    }
  }
  node.children.forEach((el) => sanitizeDocumentNode(el, allowedTags, allowedAttributes))
}

function shouldRemoveHref (href) {
  if (!href) {
    return true
  }
  const allowedProtocols = ['http', 'https', 'mailto']
  if (href.includes(':') && !allowedProtocols.includes(href.split(":")[0])) {
    return true
  }
  return false
}
