// ============================================================
//          MIXIN: User Actions
// ------------------------------------------------------------
import api from 'api'
import { mapActions, mapGetters } from 'vuex'

export const userActions = {
  computed: {
    ...mapGetters(['userInfo', 'currentUserChat'])
  },
  methods: {
    ...mapActions(['setUserInfo', 'resetState']),

    /**
     * Check if username exists
     * @method checkUsername
     * @param  {String}      username
     */
    __user__checkUsername(username) {
      return api.call('checkUsernameExists', username)
    },

    /**
     * Check if email exists
     * @method checkEmail
     * @param  {String}      email
     */
    __user__checkEmail(email) {
      return api.call('checkEmailExists', email)
    },

    /**
     * Register User
     * @method registerUser
     * @param  {Object}     data Contains Form-Data
     */
    __user__registerUser(data) {
      return api.call('registerUser', false, data)
    },

    /**
     * Confirm Logout
     */
    __user__confirmLogout() {
      let message = this.$t('messages.dialog.logout')
      const hasChat =
        this.currentUserChat &&
        ['pending', 'active'].indexOf(this.currentUserChat.status) > -1
      if (hasChat) {
        let confirmKey = `chat.${this.currentUserChat.status}LogoutConfirm`
        message = `${this.$t(confirmKey)}<br>${message}`
      }
      this.$confirm(message, {
        confirmButtonText: this.$t('system.ok'),
        cancelButtonText: this.$t('system.cancel'),
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(async () => {
        try {
          if (hasChat) {
            let apiCall = 'closeChat'
            if (this.currentUserChat.status === 'pending') {
              apiCall = 'cancelChat'
            }
            await api.call(apiCall, this.currentUserChat.id)
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.__user__logout()
        }
      })
    },

    /**
     * Logout Action
     * @method logout
     */
    __user__logout(goToStart = true, query = false) {
      this.$cookie.delete('token')
      this.$cookie.delete('messageRead')
      this.resetState()
      api.call('unsetAuthToken')

      if (goToStart) {
        this.$router.push({
          name: this.$route.meta.requiresAuth ? 'index' : this.$route.name,
          query: query || this.$route.query
        })
      }
    },

    /**
     * Check if User is Loggedin
     */
    __user__userIsLoggedIn() {
      return this.$cookie.get('token')
    },

    /**
     * Get User-ID from cookie
     */
    __user__getUserInfoFromCookie() {
      const token = this.$cookie.get('token')
      if (!token) {
        this.$error('No token Cookie')
        const redirect =
          (this.$route && this.$route.meta.requiresAuth) !== undefined
        this.__user__logout(redirect)
        return
      }
      api.call('setAuthToken', false, token)
    },

    /**
     * Get User-Info by ID
     */
    __user__getUserInfoByID(id) {
      return api
        .call('getUserInfo', id)
        .then(response => {
          this.setUserInfo(response.data)
        })
        .catch(error => {
          this.$error(error)
        })
    },

    /**
     * Change User Data
     */
    __user__changeUserData(data) {
      return api.call('changeUserData', null, data)
    },

    /**
     * Change User Password
     */
    __user__changePassword(data) {
      return api.call('changePassword', null, data)
    },

    /**
     * Change User Password
     */
    __user__deleteAccount() {
      return api.call('deleteAccount')
    },

    __user__confirmGdpr() {
      return api.call('confirmGdpr')
    }
  }
}
