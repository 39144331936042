import api from 'api'

export default {
  getters: {
    chatDialogMessages: (state, getters, rootState) =>
      getters['entities/chatDialogMessages/query']()
        .where('rootId', rootState.route.params.dialogId)
        .get()
  },
  actions: {
    markAsRead: async function({ dispatch }, ids) {
      await api.call(
        'markAsRead',
        { rootId: ids.rootId, id: ids.id },
        { read: 1 }
      )
      dispatch('entities/chatDialogMessages/update', {
        where: ids.id,
        data: {
          read: true
        }
      })
    },

    removeDialogItem: function({ dispatch }, id) {
      dispatch('entities/chatDialogMessages/delete', id)
    },

    clearDialog({ dispatch }) {
      dispatch('entities/chatDialogMessages/deleteAll')
    }
  }
}
