/* eslint-disable no-undef */
import { Model } from '@vuex-orm/core'

export default class Announcement extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'announcement'

  static primaryKey = 'id'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  /* eslint-disable camelcase */
  static fields() {
    return {
      id: this.attr(null),
      active: this.attr(false),
      created: this.attr(null),
      text: this.attr(''),
      title: this.attr(''),
      type: this.attr(null),
      updated: this.attr(null)
    }
  }
}
