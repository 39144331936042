// ============================================================
//          MIXIN: Autosave Actions
// ------------------------------------------------------------
import { mapActions } from 'vuex'
import api from 'api'
import debounce from 'lodash/debounce'

export const autoSaveActions = {
  methods: {
    ...mapActions(['setEditMessage']),

    $_autoSave_action: async function(message) {
      this.autoSaving = true

      try {
        if (this.sending || !message.draft) return

        const response = await api.call('sendMessage', message, message)

        message.id = response.data
        delete message.body
        this.setEditMessage(message)
        setTimeout(() => {
          this.autoSaving = false
          this.autoSaved = true

          setTimeout(() => {
            this.autoSaved = false
          }, 2000)
        }, 500)
      } catch (error) {
        this.$error(error)
        this.autoSaving = false
      }
    },

    $_autoSave_debounced: debounce(
      function(message) {
        this.$_autoSave_action(message)
      },
      1300,
      {
        leading: false,
        trailing: true
      }
    ),

    $_autoSave_save(message) {
      if (!this.autoSaving && !this.sending) {
        this.$_autoSave_debounced(message)
      }
    }
  }
}
