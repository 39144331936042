/* eslint-disable no-undef */
import { Model } from '@vuex-orm/core'

export default class User extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'user'

  static primaryKey = 'id'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      disableHint: this.attr(false),
      gender: this.attr(null),
      lastlogin: this.attr(null),
      mailadress: this.attr(''),
      messagesSentCount: this.attr(0),
      chatEventCount: this.attr(0),
      notification: this.attr(''),
      threadCount: this.attr(0),
      token: this.attr(null),
      feedbackToolEnabled: this.attr(false),
      roleName: this.attr(''),
      unreadCount: this.attr(0),
      username: this.attr(''),
      yearOfBirth: this.attr(null),
      gdprUpdate: this.attr('0'),
      lastEventId: this.attr(null),
      currentChatId: this.attr(null),
      pausentaste: this.attr(false),
      counselingCenterId: this.attr(null),
      counselingCenterDeleted: this.attr(false),
      counselingEventsEnabled: this.attr(false)
    }
  }
}
