const colors = [
  {
    name: 'primary-color',
    saturation: '80%',
    lightness: '23%'
  },
  {
    name: 'primary-color-dark',
    saturation: '100%',
    lightness: '13%'
  },
  {
    name: 'primary-color-lighter',
    saturation: '73%',
    lightness: '41%'
  },
  {
    name: 'secondary-color-dark',
    saturation: '79%',
    lightness: '31%'
  },
  {
    name: 'secondary-color-light',
    saturation: '58%',
    lightness: '77%'
  },
  {
    name: 'small-bg-color-right',
    saturation: '80%',
    lightness: '23%'
  },
  {
    name: 'dialog-dark',
    saturation: '89%',
    lightness: '11%'
  },
  {
    name: 'highlight-bg',
    saturation: '80%',
    lightness: '48%'
  },
  {
    name: 'highlight-text',
    saturation: '80%',
    lightness: '13%'
  },
  {
    name: 'link-hover',
    saturation: '81%',
    lightness: '8%'
  },
  {
    name: 'background-zebra',
    saturation: '38%',
    lightness: '39%'
  },
  {
    name: 'background-hover',
    saturation: '80%',
    lightness: '43%'
  }
]

const availableColors = [
  'primary-color',
  'primary-color-dark',
  'primary-color-lighter',
  'secondary-color-dark',
  'secondary-color-light',
  'small-bg-color-right',
  'dialog-dark',
  'highlight-bg',
  'highlight-text',
  'link-hover',
  'background-zebra',
  'background-hover'
]

export const setColorsFromHue = hue => {
  const root = document.documentElement
  colors.forEach(el => {
    root.style.setProperty(
      `--${el.name}`,
      `hsl(${hue}, ${el.saturation}, ${el.lightness})`
    )
  })
}

export const setThemeFromObject = config => {
  const root = document.documentElement
  config.forEach(el => {
    if (!availableColors.includes(el.name)) {
      console.error('unknown color name ' + el.name)
      return
    }
    root.style.setProperty(`--${el.name}`, el.color)
  })
}

export const convertColorConfig = colorConfig => {
  if (!colorConfig) {
    console.error('undefinedColorConfig')
    return
  }
  return [
    {
      name: 'primary-color',
      color: colorConfig.primaryColor
    },
    {
      name: 'primary-color-dark',
      color: colorConfig.primaryColorDark
    },
    {
      name: 'primary-color-lighter',
      color: colorConfig.primaryColorLighter
    },
    {
      name: 'secondary-color-dark',
      color: colorConfig.secondaryColorDark
    },
    {
      name: 'secondary-color-light',
      color: colorConfig.secondaryColorLight
    },
    {
      name: 'small-bg-color-right',
      color: colorConfig.smallBgColorRight
    },
    {
      name: 'dialog-dark',
      color: colorConfig.dialogDark
    },
    {
      name: 'highlight-bg',
      color: colorConfig.highlightBg
    },
    {
      name: 'highlight-text',
      color: colorConfig.highlightText
    },
    {
      name: 'link-hover',
      color: colorConfig.linkHover
    },
    {
      name: 'background-zebra',
      color: colorConfig.backgroundStriped
    },
    {
      name: 'background-hover',
      color: colorConfig.backgroundHover
    }
  ]
}
